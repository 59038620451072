import React from "react"
import Col from "react-bootstrap/Col"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import ContentWrapper from "../components/contentWrapper"

export default function defaultContent(props) {
  const {pageContext, data} = props;
  const promoImage = data.markdownRemark.frontmatter.promoImage?.childImageSharp.fluid
  return (
    <ContentWrapper title={pageContext.title} category={pageContext.category}>
      <Col lg={{ span: 8, offset: 2 }} as='article'>
        <h1 className='text-uppercase'>{pageContext.category}</h1>
        <h4>{pageContext.title}</h4>
        {promoImage && (
          <figure>
            <Img fluid={promoImage} className='rounded' alt={`Symbolbild ${pageContext.title}`}/>
            <small>{pageContext.imageDisclaimer}</small>
          </figure>
        )}
        <div dangerouslySetInnerHTML={{ __html: pageContext.html }} />
      </Col>
    </ContentWrapper>
  )
}

export const query = graphql`
    query PromoImageQuery($slug: String!) {
        markdownRemark(frontmatter: {slug: {eq: $slug}}) {
            html
            frontmatter {
                title
                promoImage {
                    childImageSharp {
                        fluid(maxWidth: 800) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`
